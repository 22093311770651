<template>
  <div class="content-wrapper platform-goods-list">
    <!-- Main content -->
    <section class="content">
      <section class="clearfix" style="margin-bottom: 1%" v-if="country === 'TH'">
        <a-button type="primary" @click="goToAddProduct(true)">
          <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
          {{ $t('新建店铺商品') }}
        </a-button>
        <a-button type="primary" @click="openStoreModal">
          {{ $t('更新库存') }}
        </a-button>
      </section>
      <div class="tag-select">
        <div class="flex-ct tag-select-item mb10">
          <div class="label">{{ $t('异常') }}：</div>
          <a-tag
            v-for="item in abnormalList"
            :key="item.status"
            :color="formData.tag === item.status ? '#1890ff' : ''"
            @click="setAbnormalType(item.status)"
          >
            <span>{{ item.statusName }}</span>
            <a-popover v-if="item.tips">
              <template slot="content">
                <p v-for="(elem, index2) in item.tips" :key="index2">{{ $t(elem) }}</p>
              </template>
              <a-icon class="ml2" type="question-circle"></a-icon>
            </a-popover>
          </a-tag>
        </div>
      </div>
      <a-row>
        <a-col :span="6" style="margin-right: 15px">
          <a-select
            style="width: 100%"
            :placeholder="$t('平台')"
            v-model="formData.platform"
            allow-clear
          >
            <a-select-option v-for="(item, index) in platformList" :value="item" :key="index">
              {{ platformMapping[item] }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6" style="margin-right: 15px">
          <a-input
            type="text"
            v-model.trim="formData.nameOrSellerSku"
            :placeholder="
              $t(`请输入`) + $t(`商家编码`) + '/' + $t(`商品名称`) + '/' + $t(`平台商家编码`)
            "
          />
        </a-col>
        <a-col :span="6" style="margin-right: 15px" v-if="false">
          <CategoryList
            :canNotAdd="true"
            @handleCategoryChange="handleCategoryChange"
          ></CategoryList>
        </a-col>
        <a-button
          type="primary"
          @click="queryPlatformSkuPageList(1)"
          v-if="priv('f_platform_goods_list')"
        >
          <i class="icon iconfont icon-search" style="margin-right: 5px"></i>
          {{ $t(`查询`) }}
        </a-button>
        <a-button @click="reset">
          <i class="iconfont icon-synchronize" style="margin-right: 5px"></i>
          {{ $t(`重置`) }}
        </a-button>
        <a-button @click="onExport" style="margin-left: 15px">
          <i class="icon iconfont icon-upload" style="margin-right: 5px"></i>
          {{ $t(`导出`) }}
        </a-button>
      </a-row>
      <div class="table-content mt10">
        <div class="table-body" v-if="tableShow">
          <a-table
            :columns="tableColumns"
            :data-source="tableData"
            :pagination="false"
            :rowKey="(record, index) => index"
            :scroll="{
              x: 1500,
            }"
          >
            <template slot="status" slot-scope="text">
              {{ omsStatusMapping[text] }}
            </template>
            <template slot="goodsName" slot-scope="text, record">
              <div class="goods-name">{{ text }}</div>
              <div class="goods-main-image" v-if="record.images?.length">
                <img :src="record.images[0]" class="main-image" />
                <div class="image-list flex-ct">
                  <ImagePreview
                    :urls="record.images"
                    imgStyle="width: 50px; height: 50px;"
                    style="flex-wrap: nowrap"
                  />
                </div>
              </div>
            </template>
            <template slot="shopName" slot-scope="text, record">
              <div class="platform">{{ record.platform }}</div>
              <div class="platform">{{ record.shopName }}</div>
            </template>
            <div slot="action" slot-scope="text, record" class="flex flex-col">
              <a-button type="link" @click="createOMSGoods(record)">
                {{ $t('创建OMS商品') }}
              </a-button>
              <a-button
                v-if="record.description"
                type="link"
                @click="showFullDescription(record.description)"
              >
                {{ $t('查看描述') }}
              </a-button>
            </div>
            <span slot="sellerSku" slot-scope="text, scope">
              <div class="flex-ct gap-4" v-if="scope.editable">
                <SearchSku
                  :partyid="$store.getters.partyId"
                  :isGroupGoods="true"
                  style="width: 100%"
                  :placeholder="$t(`商品名称`) + '/' + $t(`商家编码`)"
                  @selected="
                    (data) => {
                      selectedAddSku(scope, data)
                    }
                  "
                />
                <a-icon type="check" @click="modifySellerSku(scope)" />
                <a-icon type="close" @click="closeHandle(scope)" />
              </div>
              <div v-else class="flex-ct gap-4">
                <span>{{ text }}</span>
                <a-icon type="edit" class="editable-cell-icon" @click="editHandle(scope)" />
              </div>
              <div class="mt10" v-if="scope.tagList.length">
                <a-tag v-for="item in scope.tagList" :key="item.tag" color="red" class="ml4">
                  {{ item.tagName }}
                </a-tag>
              </div>
            </span>
          </a-table>
        </div>
        <div class="table-footer">
          <pagination
            :pageNo="pageNo"
            :length="pageSize"
            :total-count="total"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </section>
    <commitLazadaGoods
      :visible.sync="visible"
      :itemId="modalItem.itemId"
      @handleOk="handleOk"
    ></commitLazadaGoods>
    <a-modal
      v-model="updateInventoryVisible"
      :title="$t('更新库存')"
      :confirmLoading="confirmLoading"
      @ok="goToSyncStore"
      @cancel="updateInventoryVisible = false"
      :bodyStyle="bodyStyle"
    >
      <a-form-model>
        <a-form-model-item :label="$t(`店铺`)">
          <SelectShop
            @selected="selectedSyncShop"
            :platform="formData.platform"
            style="width: 400px"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="descriptionVisible" :title="$t('商品描述')" :footer="null" width="800px">
      <div class="description-content" v-html="currentDescription"></div>
    </a-modal>
  </div>
</template>
<script>
import http from '@service/axios'
import api from '@service/api'
import { mapState, mapGetters, mapMutations } from 'vuex'
import pagination from '@component/pagination'
import SelectShop from '@component/selectShop'
import commitLazadaGoods from '@component/commitLazadaGoods'
import CategoryList from '@component/categoryList'
import SearchSku from '@component/searchSku'
import ImagePreview from '@component/ImagePreview'
import { customCell as customCellHandle } from '@/common'

export default {
  name: 'platformGoods',
  components: {
    pagination,
    commitLazadaGoods,
    SelectShop,
    CategoryList,
    SearchSku,
    ImagePreview,
  },
  mounted() {
    const dashboardStatus = this.$route.query.dashboardStatus
    if (dashboardStatus) {
      this.formData.tag = dashboardStatus
    }
    this.queryPlatformSkuPageList(1)
  },
  data() {
    return {
      bodyStyle: {
        width: '430px',
        maxHeight: '600px',
        overflowY: 'scroll',
      },
      tabGutter: 10,
      visible: false,
      formData: {
        nameOrSellerSku: undefined,
        categoryName: undefined,
        omsStatus: undefined,
        shopCode: undefined,
        platform: undefined,
      },
      pageNo: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      modalItem: {},
      confirmLoading: false,
      updateInventoryVisible: false,
      syncStoreCode: '',
      tableShow: true,
      descriptionVisible: false,
      currentDescription: '',
    }
  },
  provide() {
    return {
      page: 'platformGoodsList',
    }
  },
  computed: {
    ...mapState(['shopList']),
    ...mapGetters(['platformMapping']),
    tableColumns() {
      return [
        {
          title: this.$t('商品名称'),
          dataIndex: 'name',
          width: 250,
          scopedSlots: { customRender: 'goodsName' },
          customCell: this.customCell,
        },
        {
          title: this.$t('店铺'),
          dataIndex: 'shopName',
          width: 200,
          scopedSlots: { customRender: 'shopName' },
          customCell: this.customCell,
        },
        {
          title: this.$t('商家编码'),
          dataIndex: 'sellerSku',
          width: 300,
          scopedSlots: { customRender: 'sellerSku' },
        },
        {
          title: this.$t('平台商家编码'),
          dataIndex: 'platformSku',
          width: 250,
        },
        {
          title: this.$t('价格'),
          dataIndex: 'price',
          width: 150,
        },
        {
          title: this.$t('库存'),
          dataIndex: 'quantity',
          width: 150,
        },
        {
          title: this.$t('类目'),
          dataIndex: 'categoryList',
          customRender: (text) => {
            return text?.map((item) => item.categoryName).join('/')
          },
          width: 250,
          customCell: this.customCell,
        },
        {
          title: this.$t('状态'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          customCell: this.customCell,
          width: 150,
        },
        {
          title: this.$t('操作'),
          dataIndex: 'action',
          width: 200,
          customCell: this.customCell,
          scopedSlots: { customRender: 'action' },
          fixed: 'right',
        },
      ]
    },
    omsStatusMapping() {
      /**
        DRAFT(1, "DRAFT"),
        PENDING(2, "PENDING"),
        FAILED(3, "FAILED"),
        ACTIVATE(4, "ACTIVATE"),
        SELLER_DEACTIVATED(5, "SELLER_DEACTIVATED"),
        PLATFORM_DEACTIVATED(6, "PLATFORM_DEACTIVATED"),
        FREEZE(7, "FREEZE"),
        DELETED(8, "DELETED");
      */
      return {
        DRAFT: this.$t('草稿'),
        PENDING: this.$t('待提交'),
        FAILED: this.$t('提交失败'),
        ACTIVATE: this.$t('已提交'),
        SELLER_DEACTIVATED: this.$t('商家停用'),
        PLATFORM_DEACTIVATED: this.$t('平台停用'),
        FREEZE: this.$t('冻结'),
        DELETED: this.$t('已删除'),
      }
    },
    platformList() {
      return Array.from(new Set(this.$store.state.nowPlatformList?.map((item) => item.code)))
    },
    country() {
      return this.$store.getters.country
    },
    abnormalList() {
      const list = [
        {
          status: 'EMPTY_SELLER_CODE',
          statusName: this.$t('商家编码为空'),
        },
        {
          status: 'NO_OMS_GOODS',
          statusName: this.$t('未创建OMS商品'),
        },
        {
          status: 'NO_STOCK',
          statusName: this.$t('库存不足'),
        },
      ]
      return list
    },
    tableHeight() {
      return (window.innerHeight - 450) * this.$store.state.rate
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      for (let item in this.formData) {
        this.formData[item] = undefined
      }
      this.pageNo = 1
      this.pageSize = 10
      this.formData.platform = this.platformList[0]
      setTimeout(() => {
        this.queryPlatformSkuPageList(1)
      }, 100)
    },
    handleCategoryChange(data) {
      if (data) {
        this.formData.categoryId = data.value
        this.formData.categoryName = data.label
      } else {
        this.formData.categoryId = ''
        this.formData.categoryName = ''
      }
    },
    selectedSyncShop(data) {
      this.syncStoreCode = data.code
    },
    queryPlatformSkuPageList(first_page = false) {
      this.tableShow = false
      first_page && (this.pageNo = 1)
      this.$nextTick(() => {
        http({
          type: 'post',
          hasLoading: true,
          url: api.queryPlatformSkuPageList,
          data: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            tag: this.formData.tag,
            omsStatus: this.formData.omsStatus,
            categoryName: this.formData.categoryName,
            nameOrSellerSku: this.formData.nameOrSellerSku,
            platform: this.formData.platform,
          },
          success: (res) => {
            let platformSkuList = res.result?.list
            if (!platformSkuList) {
              this.tableData = []
              return
            }
            // 格式化数据
            this.tableData = this.formatTableData(platformSkuList)
            this.total = res.result.total
            this.tableShow = true
          },
        })
      })
    },
    formatTableData(data) {
      let result = []
      data.forEach((item) => {
        // SKU列表数据
        const skuList = item.skuList.map((sku) => ({
          ...sku,
          ...item,
          tagList: sku.tagList,
        }))

        result.push(...skuList)
      })
      return result
    },
    onExport() {
      let url =
        api.exportLazadaSku +
        `?nameOrSellerSku=${this.formData.nameOrSellerSku}&categoryName=${this.formData.categoryName}&omsStatus=${this.formData.omsStatus}&brand=`
      window.open(url)
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryPlatformSkuPageList()
    },
    handleOk() {
      this.tableData.forEach((item) => {
        if (item.itemId === this.modalItem.itemId) {
          item.omsStatus = 'COMMITTED'
        }
      })
    },
    openStoreModal() {
      this.updateInventoryVisible = true
    },
    // 快速新建商品
    goToAddProduct(quickCreate) {
      let obj = {
        name: 'productsDetail',
        view: 'productsDetail',
        query: { type: 'add', quickCreate },
      }
      localStorage.setItem('from_create', 1)
      this.addPane(obj)
      this.$router.push(obj)
    },
    goToSyncStore() {
      const merchantId = JSON.parse(localStorage.getItem('userInfo'))?.merchantId
      http({
        type: 'post',
        hasLoading: true,
        url: api.goodsSyncSkuInventory,
        data: {
          shopCode: this.syncStoreCode,
          merchantId: merchantId,
        },
        success: () => {
          this.$success(this.$t('已开启同步'))
        },
      })
    },
    createOMSGoods(record) {
      console.log(record)
      localStorage.setItem('platformGoodsInfo', JSON.stringify(record))
      this.$router.push('/productsDetail')
    },
    async modifySellerSku(scope) {
      console.log(scope)
      try {
        const res = await http({
          url: api.updatePlatformGoodsOmsSkuCode,
          data: {
            platform: scope.platform,
            platformGoodsId: scope.platformGoodsId,
            platformSkuId: scope.platformSku,
            omsSkuCode: scope.omsSkuCode,
          },
        })
        if (res.success) {
          this.$success(this.$t('修改成功'))
        }
      } finally {
        this.closeHandle(scope)
      }
    },
    editHandle(record) {
      this.$set(record, 'editable', true)
    },
    closeHandle(record) {
      this.$set(record, 'editable', false)
    },
    selectedAddSku(scope, data) {
      console.log(data)
      this.$set(scope, 'omsSkuCode', data.skuCode)
    },
    setAbnormalType(status) {
      if (this.formData.tag === status) {
        this.formData.tag = undefined
      } else {
        this.formData.tag = status
      }
      this.$nextTick(() => {
        this.queryPlatformSkuPageList(1)
      })
    },
    showFullDescription(text) {
      this.currentDescription = text
      this.descriptionVisible = true
    },
    customCell(record, index) {
      return customCellHandle(record, index, this.tableData, 'platformGoodsId')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.action-list {
  display: flex;
}
.flex-btns {
  max-width: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .t-btn {
    cursor: pointer;
    margin-right: 10px;
    color: $primary-color;
    white-space: nowrap;
  }
}
.text-info {
  cursor: pointer;
  position: relative;
  &:after {
    content: '';
    padding: 10px;
  }
}
.bgcBtn {
  color: $primary-color;
  border-color: $primary-color;
  margin-right: 12px;
}
.ant-modal-confirm .ant-modal-body {
  white-space: pre-wrap;
}
.platform-goods-list .tag-select ::v-deep .ant-tag {
  font-size: 13px;
  padding: 2px 10px;
  cursor: pointer;
}
.description-preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.description-content {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;

  :deep(img) {
    max-width: 100%;
    height: auto;
  }
}
.goods-main-image {
  position: relative;
  width: 50px;
  margin-top: 4px;

  .main-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    cursor: pointer;
  }

  .image-list {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    max-width: 320px;
    overflow-x: auto;
    white-space: nowrap;
  }

  &:hover {
    .image-list {
      display: block;
    }
  }
}
</style>

<style lang="scss">
.resize-table-th {
  position: relative;
  .table-draggable-handle {
    height: 100% !important;
    bottom: 0;
    left: auto !important;
    right: -5px;
    cursor: col-resize;
    touch-action: none;
  }
}
</style>
