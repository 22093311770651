<template>
  <div class="flex">
    <a-input :value="defaultName" v-if="showName" @focus="showName = false" />
    <a-cascader
      v-else
      :options="categoryList"
      :load-data="loadData"
      v-model="value"
      :disabled="disabled"
      :placeholder="$t(`请输入`) + '/' + $t(`选择`) + $t(`商品类目`)"
      change-on-select
      @change="onChange"
      style="width: 100%"
    />
    <a-button
      style="margin-left: 5px"
      type="primary"
      v-if="!disabled && !canNotAdd"
      @click="visiable = true"
    >
      {{ $t('新增类目') }}
    </a-button>
    <a-modal width="800px" v-model="visiable" :title="$t('添加规格')" @ok="createCategory">
      <a-form-model>
        <a-form-model-item :label="$t('请输入类目名称')" required>
          <a-input v-model="modalData.categoryName"></a-input>
        </a-form-model-item>
        <a-form-model-item :label="$t(`类目`)" required>
          <categoryTree
            ref="categoryTree"
            :show="true"
            @changeCategory="changeCategory"
          ></categoryTree>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import categoryTree from '@component/categoryTree'
export default {
  //本地分类列表
  name: 'categoryList',
  props: ['placeholder', 'defaultValue', 'defaultName', 'disabled', 'canNotAdd'],
  components: {
    categoryTree,
  },
  data() {
    return {
      categoryList: [],
      value: [],
      visiable: false,
      modalData: {
        categoryName: '',
        categoryId: '',
      },
      showName: false,
    }
  },
  mounted() {
    this.queryCategoryList().then((list) => {
      this.categoryList = list
    })
  },
  methods: {
    clear() {
      this.value = []
    },
    loadData(selectedOptions) {
      // console.log(selectedOptions)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      // console.log('targetOption', targetOption)
      // load options lazily
      this.queryCategoryList(targetOption.value).then((list) => {
        targetOption.loading = false
        if (list.length > 0) {
          targetOption.children = list
        } else {
          targetOption.isLeaf = true
        }
        this.categoryList = [...this.categoryList]
      })
    },
    queryCategoryList(parentId = undefined) {
      return new Promise((resolve) => {
        http({
          type: 'post',
          url: api.queryCategoryList,
          data: {
            parentId,
          },
          success: (res) => {
            const list = res.result.categoryList.map((item) => ({
              label: item.categoryName,
              value: item.categoryId,
              isLeaf: item.leaf,
            }))
            this.setDefaultValue(this.defaultValue)
            resolve(list)
          },
        })
      })
    },
    onChange(value, selectedOptions) {
      // console.log('value, selectedOptions: ', value, selectedOptions)
      if (selectedOptions?.length > 0) {
        let data = selectedOptions[selectedOptions.length - 1]
        if (data.isLeaf) {
          const label = selectedOptions.map((item) => item.label).join('/')
          this.value = value
          this.$emit('handleCategoryChange', { ...data, label })
          this.$nextTick(() => {
            this.$emit('changeList', 'speed')
          })
        }
      } else {
        this.$emit('handleCategoryChange', {})
      }
    },
    createCategory() {
      if (!this.modalData.categoryName) {
        this.$warning(this.$t(`请输入规格名`))
        return
      }
      if (!this.modalData.categoryId) {
        this.$warning(this.$t(`请选择对应Lazada规格`))
        return
      }
      http({
        type: 'post',
        hasLoading: true,
        url: api.createCategory,
        data: {
          categoryName: this.modalData.categoryName,
          lazadaCategoryId: this.modalData.categoryId,
        },
        success: (res) => {
          this.$emit('createCategory', {
            categoryName: this.modalData.categoryName,
            categoryId: res.result.categoryId,
          })
          this.queryCategoryList()
          this.$success(this.$t(`创建成功`))
          this.modalData.categoryName = ''
          this.modalData.categoryId = ''
          this.$refs.categoryTree.clear()
          this.visiable = false
        },
      })
    },
    changeCategory(data) {
      if (data.value.length) {
        this.modalData.categoryId = data.value[data.value.length - 1]
      } else {
        this.modalData.categoryId = ''
      }
    },
    setDefaultValue(val) {
      if (val && this.categoryList.length > 0) {
        let parentItem = this.categoryList.find((item) => {
          return item.value === val.categoryId
        })
        if (parentItem) {
          this.value = [val.categoryId]
        } else {
          this.categoryList.forEach((item) => {
            item.children?.forEach((elem) => {
              if (elem.value == val) {
                this.value = [item.value, val]
              }
            })
          })
        }
      } else {
        this.value = []
      }
    },
  },
  watch: {
    defaultValue(val) {
      this.setDefaultValue(val)
    },
    defaultName: {
      handler(val) {
        val && (this.showName = true)
      },
      immediate: true,
    },
  },
}
</script>

<style scoped lang="scss">
.flex {
  position: relative;
  align-items: center;
}
.category-text {
  width: 100px;
  padding-left: 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
  transform: translateX(100%);
}
</style>
